var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-reservation-show-text".concat(_vm.idModal),
      "title": _vm.titleModal,
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "busy": !_vm.text
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Đóng ")])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.text
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "position-absolute p-75 rounded-circle m-25",
    staticStyle: {
      "top": "0",
      "right": "10px",
      "z-index": "9999"
    },
    attrs: {
      "variant": "flat-secondary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": _vm.handleCopyText
    }
  }, [_c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "text-dark",
    attrs: {
      "icon": "CopyIcon",
      "size": "21",
      "title": _vm.$t('copy')
    }
  })], 1), _c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "height": "70vh",
      "overflow": "auto"
    },
    attrs: {
      "rows": "16",
      "max-rows": "40"
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }