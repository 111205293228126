<template>
  <b-modal
    :id="`modal-reservation-show-text${idModal}`"
    :title="titleModal"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    :busy="!text"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        variant="secondary"
        @click="cancel"
      >
        Đóng
      </b-button>
    </template>

    <IAmOverlay :loading="!text">
      <div class="position-relative">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-secondary"
          class="position-absolute p-75 rounded-circle m-25"
          style="top: 0; right: 10px; z-index: 9999;"
          :disabled="disabledCopy"
          @click="handleCopyText"
        >
          <feather-icon
            v-b-tooltip.hover.window
            icon="CopyIcon"
            size="21"
            class="text-dark"
            :title="$t('copy')"
          />
        </b-button>
        <b-form-textarea
          v-model="text"
          class="font-weight-bolder text-uppercase"
          rows="16"
          max-rows="40"
          style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif; height: 70vh; overflow: auto;"
        />
      </div>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import { BButton, VBTooltip, BFormTextarea } from 'bootstrap-vue'
import { ref, toRefs } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

export default {
  components: {
    BButton,
    BFormTextarea,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    idModal: {
      type: String,
      default: () => '',
    },
    titleModal: {
      type: String,
      default: 'Xem vé rút gọn',
    },
    text: {
      type: [String, null],
      default: null,
    },
  },
  setup(props) {
    const { copy } = useClipboard()
    const { text } = toRefs(props)

    const loading = ref(false)
    const disabledCopy = ref(false)

    function handleCopyText() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(text.value)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    return {
      loading,
      disabledCopy,
      handleCopyText,
    }
  },
}
</script>
